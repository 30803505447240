<script setup>
import { ref, computed, defineEmits, defineProps, watch} from 'vue';
import Counter from "./Counter.vue";

const props = defineProps({
  lineItems: Array,
  variants: {
    type: Array,
    default: [],
  },
  hasOperatingBtn: {
    type: Boolean,
    default: false,
  },
  disabledUpdate: {
    type: Boolean,
    default: false
  },
  
});

const emit = defineEmits(['updateItem', "deleteItem", "addSize"]);

const variantOptions = computed(() => {
  return props.variants?.map(variant => variant.size).filter(size => !props.lineItems.find(item => item.size === size))
});

// 判斷是否還有可用尺寸的計算屬性
const hasAvailableSizes = computed(() => variantOptions.value.length > 0);

const selectedVariant = ref(variantOptions.value[0]);

const sizeToVariantID = computed(() => {
  const result = {};
  props.variants.forEach(variant => {
    result[variant.size] = variant.size_id;
  });
  return result;
});

const totalCount = computed(() => {
  return props.lineItems.reduce((sum, item) => sum + item.quantity, 0);
})

const itemCountUpdate = (index, newQuantity) => {
  const updatedItems = [...props.lineItems];
  updatedItems[index].quantity = newQuantity;
  emit('updateItem', updatedItems[index]);
}

const deleteItem = (item) => {
  emit('deleteItem', item)
}

const addSize = () => {
  const variantItem = {
    variantsID : sizeToVariantID.value[selectedVariant.value],
    size: selectedVariant.value
  }
  emit('addSize', variantItem)
}

watch(() => props.lineItems.length, () => { // item 數量發生改變時
  selectedVariant.value = variantOptions.value[0]
})

</script>

<template>
  <!-- 遍歷 items 陣列中的每個尺寸項目，傳遞給 Counter 元件 -->
  <Counter v-for="(item, index) in lineItems" :key="item.line_item_id" :item="item" :hasOperatingBtn="hasOperatingBtn" :disabledUpdate="disabledUpdate"
    @countUpdated="itemCountUpdate(index, $event)" 
    @deleteItem="deleteItem(item)" 
  />

  <!-- 增添尺碼部分 -->
  <div class="mt-4 px-0" v-if="hasAvailableSizes && hasOperatingBtn">
    <p class="add-size-label">增添尺碼 </p>
    <div class="d-flex mt-3">
      <!-- 下拉選擇框，綁定當前選擇的尺寸 -->
      <select class="form-select" v-model="selectedVariant">
        <!-- 遍歷可用尺寸選項，動態生成下拉選項 -->
        <option v-for="size in variantOptions" :key="size" :value="size">{{ size }}</option>
      </select>
      <!-- 點擊按鈕以添加選中的尺寸項目 -->
      <button @click="addSize" type="button" class="btn btn-outline-secondary">
        新增
      </button>
    </div>
  </div>

  <!-- 顯示總計數量 -->
  <template v-if="!hasOperatingBtn">
    <hr>
    <h3 class="text-end me-3 fs-5">小計 {{ totalCount }} / 件</h3>
  </template>
</template>

<style scoped>

.add-size-label {
  display: inline;
  padding: 0 1rem;
  border-radius: 50rem;
  letter-spacing: 5px;
  color: #000;
  background-color: rgba(248, 249, 250);
}
.form-select {
  width: 120px;
  margin-right: 10px;
}
</style>