export default class App {
  constructor() {
    this.init();
  }

  init() {
    this.activeIOSPseudoCSS();
    this.preventCSSAniOnPageLoad();
  }

  // 修正Safari的CSS偽選擇器不會正常運作的問題
  activeIOSPseudoCSS() {
    document.addEventListener("touchstart", function() {}, false);
  }

  // 預防CSS動畫在載入頁面時會直接執行的問題
  preventCSSAniOnPageLoad() {
    const preventCSSAniOnPageLoadClass = "preload-transitions";
    const preventCSSAniOnPageLoadSelector = ".js-preload-transitions";

    document.onreadystatechange = function() {
      if (document.readyState === "complete") {
        const node = document.querySelector(`body${preventCSSAniOnPageLoadSelector}`);
        if (node !== null) {
          node.classList.remove(preventCSSAniOnPageLoadClass);
        }
      }
    };
  }
}