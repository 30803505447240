const flashDisappearanceClass = "mk-flash--op0";
const flashClosedAutoSelector = ".js-flash-closed--auto";
const flashClosedManualSelector = ".js-flash-closed--manual";
const flashClosedIconSelector = '.js-flash-closed';

export default class Flash {
  constructor() {
    // auto close flash
    document
    .querySelectorAll(flashClosedAutoSelector)
    .forEach((flashEl) =>{
      setTimeout(() => {
        flashEl.classList.add(flashDisappearanceClass);
        setTimeout(() => {
          flashEl.remove();
        }, "500");
      }, "2000");
    });

    // manual close flash
    document
    .querySelectorAll(flashClosedManualSelector)
    .forEach((flashEl) => {
      flashEl.querySelectorAll(flashClosedIconSelector)
      .forEach((iconEl) => {
        iconEl.addEventListener('click', () => {
          flashEl.remove();
        })
      })
    })
  }

};