<script setup>
import { defineProps, defineEmits, ref, watch} from 'vue';

const props = defineProps({
  item: Object,
  hasOperatingBtn: {
    type: Boolean,
    default: false,
  },
  disabledUpdate: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits(['countUpdated','deleteItem']);

let MIN_COUNT = props.hasOperatingBtn ? 1 : 0;
let MAX_COUNT = 999;

const quantity = ref(props.item.quantity);
const quantityInput = ref(props.item.quantity.toString())

const handleInput = ()=> {
  let intValue = parseInt(quantityInput.value, 10);
  if (!isNaN(intValue)) {
    if (intValue >= MIN_COUNT && intValue <= MAX_COUNT) {
      quantity.value = intValue;
    } else if (intValue > MAX_COUNT) {
      quantity.value = MAX_COUNT;
    } else {
      quantity.value = MIN_COUNT;
    }
  } else {
    quantity.value =  MIN_COUNT;
  }
  emitUpdatedItem();
}

const decrementItem = () => {
  if(quantity.value > MIN_COUNT) quantity.value--;
  emitUpdatedItem();
}

const incrementItem = () => {
  if(quantity.value < MAX_COUNT) quantity.value++;
  emitUpdatedItem();
}

const emitUpdatedItem = () => {
  quantityInput.value = quantity.value.toString()
  emit("countUpdated", quantity.value);
}

watch(() => props.item.quantity, (newV) => {
  quantity.value = newV;
  quantityInput.value = newV.toString();
});

</script>

<template>
  <div class="d-flex justify-content-between">
    <p class="d-flex align-items-center">
      <span class="mk-text-bg-light py-1 fw-semibold text-center">{{ item.size }}</span>
      <span v-show="Number(item.surcharge) != 0" class="markUp ms-2 py-1 rounded-2">+{{ Math.floor(item.surcharge) }}</span>
    </p>
    <div class="btn-group fw-semibold">
      <button @click="decrementItem" type="button" class="btn btn-counter inner-text" :disabled="disabledUpdate">
        -
      </button>
      <input class="w-quantity" v-model="quantityInput" @input="handleInput" :readonly="disabledUpdate"/>
      <button @click="incrementItem" type="button" class="btn btn-counter inner-text" :disabled="disabledUpdate">
        +
      </button>
       <!-- 刪除按鈕的圖示 -->
      <button v-if="hasOperatingBtn" @click.once="$emit('deleteItem')" class="btn-x py-0 my-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-x-circle opacity-25" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </button>
    </div>
  </div>
</template>  

<style scoped>
.markUp {
  padding: 0px 10px;
  color: #ffffff;
  background-color: #EE7C26;
  border-radius: 0px;
}

button.markUp:hover {
  background-color: #ff6f00;
  color: #ffffff;
}

.inner-text {
  font-size: 1.25rem;
  line-height: 0;
  padding: 0.625rem 1rem;
}

.btn-group {
  align-items: center;
}

.btn-counter {
  --bs-btn-color: #000;
  --bs-btn-bg: #D9D9D9;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #D9D9D9;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-btn-disabled-opacity: 1;
  --bs-btn-font-weight: 900;
}

.btn-x {
  background-color: transparent;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  padding-left: 10px;
}

.mk-text-bg-light {
  color: #000;
  background-color: #F2F2F2;
  width: 50px;
}

.w-quantity {
  margin: 0 0.25rem;
  outline: none;
  border: none;
  height: 1rem;
  width: 2rem;
  text-align: center;
  font-size: 1rem;
}
</style>